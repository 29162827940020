$(document).ready(function() {
    //Banner Slides
    $('.slidecontainer')
        .cycle({
            fx: 'fade',
            slides: '.hero',
            speed: 'slow',
            timeout: 6000,
            width: '100%',
            fit: 1
        });

    //Testimonial Slides
    $('.testimonial-wrap')
        .cycle({
            fx: 'fade',
            slides: '.testimonial-text',
            speed: 'slow',
            timeout: 6000,
            width: '100%',
            fit: 1
        });

    //Menu Toggle
    var menuToggle = $('#js-mobile-menu').unbind();
    $('#js-navigation-menu').removeClass("show");

    menuToggle.on('click', function (e) {
        e.preventDefault();
        $('#js-navigation-menu').slideToggle(function () {
            if ($('#js-navigation-menu').is(':hidden')) {
                $('#js-navigation-menu').removeAttr('style');
            }
        });
    });

    //Form Validation
    $("#contact-form").validate({
        errorLabelContainer: $("#contact-form div.disp-error")
    });

    $("#mot-form").validate({
        errorLabelContainer: $("#mot-form div.disp-error")
    });

    //Parallax
    $(document).ready(function() {
        if ($("#js-parallax-window").length) {
            parallax();
        }
    });

    $(window).scroll(function(e) {
        if ($("#js-parallax-window").length) {
            parallax();
        }
    });

    function parallax(){
        if( $("#js-parallax-window").length > 0 ) {
            var plxBackground = $("#js-parallax-background");
            var plxWindow = $("#js-parallax-window");

            var plxWindowTopToPageTop = $(plxWindow).offset().top;
            var windowTopToPageTop = $(window).scrollTop();
            var plxWindowTopToWindowTop = plxWindowTopToPageTop - windowTopToPageTop;

            var plxBackgroundTopToPageTop = $(plxBackground).offset().top;
            var windowInnerHeight = window.innerHeight;
            var plxBackgroundTopToWindowTop = plxBackgroundTopToPageTop - windowTopToPageTop;
            var plxBackgroundTopToWindowBottom = windowInnerHeight - plxBackgroundTopToWindowTop;
            var plxSpeed = 0.2;

            plxBackground.css('top', - (plxWindowTopToWindowTop * plxSpeed) + 'px');
        }
    }

    //Match element heights
    $('.equalise').matchHeight();

});

